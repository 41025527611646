/**
 * Important Notice:
 * 
 * If Scripts like select2, owlCarousel or fancybox are needed,
 * the have to be moved from libs_notUsed to libs. Actually only
 * the flexnav files are in the folder libs.
 */

$(function () {
  $.ajaxSetup({
    cache: true
  });

  /* scroll top button */
  $('.scrollTop').on('click', function () {
    $("html, body").animate({ scrollTop: 0 }, 'slow', 'swing');
    return false;
  });

  /* navLanguage */
  $('#language.active .current').on('click', function (e) {
    e.preventDefault();
    $(this).next().toggleClass('visible');
  });

  /* initialize FlexNav */
  $(".flexnav").flexNav({
    'calcItemWidths': false,
    'hover': false
  });

  /* clone overview pages into submenu */
  $('.menu-item-has-children > a').each(function (index, el) {
    if ($(this).parent().hasClass('append-to-sub')) {
      var target = $(this).next('ul');
      var classes = $(this).parent().attr("class");
      var text = $(this).data("title");
      $(this).clone().prependTo(target).wrap('<li></li>').text(text).parent().attr("class", classes).removeClass('menu-item-has-children item-with-ul current-page-ancestor current-menu-ancestor current-menu-parent current-page-parent current_page_parent current_page_ancestor append-to-sub');
      $(this).attr("href", "#");
    } else {
      $(this).attr("href", "#");
    }

    $(this).on('click', function (e) {
      e.preventDefault();
    });
  });

  /* searchForm */
  $('.menu-button, .searchButton, .searchForm .close').on('click', function () {
    if ($(".searchForm").hasClass('hide')) {
      $(".searchForm").removeClass('hide');
      $(".searchForm").addClass('open');
    } else {
      $(".searchForm").addClass('hide');
      $(".searchForm").removeClass('open');
    }
  });

  /* form focus */
  $("input, textarea").on('focus blur', function () {
    $(this).closest('.fieldWrapper').toggleClass('focus');
  });

  /* tooltip */
  $('abbr').tooltipster({
    side: 'bottom',
    maxWidth: '320',
    trigger: 'custom',
    triggerOpen: {
      mouseenter: true,
      touchstart: true,
      click: true,
      tap: true
    },
    triggerClose: {
      mouseleave: true,
      originClick: false,
      touchleave: true,
      click: false,
      tap: true
    }
  }).focus(function () {
    $(this).tooltipster('show');
  }).blur(function () {
    $(this).tooltipster('close');
  }).click(function (e) {
    e.preventDefault();
  });
});